<template>
  <form
    class="callback-form callbackwidget-call-form"
    novalidate
    @submit.prevent="submitThis"
    :class="[device_platform, { horizontal: horizontal }, form_class]"
  >
    <div class="callback-form__container">
      <input
        name="tel"
        type="tel"
        class="callback-form__input"
        placeholder="Введите телефон"
        v-model="phone"
        ref="phone"
        v-mask
        :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{2}.*[0-9]{2}'"
        required
      />
      <button
        type="submit"
        class="button callback-form__button button-blue"
        :class="[{ button_disabled: !valid }, buttonClass]"
        ref="submitter"
      >
        <slot></slot>
      </button>
    </div>
    <div class="agreement" v-if="CONSTANTS.need_agreement">
      <Checkbox :value="acceptance" @changed="chect"></Checkbox>
      <span class="agreement__text"> Согласен с 
        <a class="form__agreement-link" href="https://www.rolf.ru/agreement/" target="_blank" rel="noopener">
          условиями обработки персональных данных
        </a>
      </span>
    </div>
  </form>
</template>

<script>
import Mixin from "../../common/mixin";
import Mask from "../../common/mask";
import Checkbox from "../checkbox.vue";
import sendMetric from '../../common/sendMetric.js'

export default {
  name: "callback-input",
  components: { Checkbox },
  directives: { Mask },
  mixins: [Mixin],
  emits: ["callBack","getAgreement", "sendMetricGoal"],
  props: ["horizontal", "data", "form_class", "focusOnMount", "button_class",'agreement__class'],
  data() {
    return {
      acceptance: true,
      phone: "",
    };
  },
  computed: {
    agreed() {
      return this.acceptance;
    },
    valid() {
      let phone = this.phone.replace(/\D+/g, "");
      return this.agreed && phone.length === 11;
    },
    buttonClass() {
      return this.button_class || "button-dark-blue";
    },
  },
  mounted() {
    if (this.focusOnMount) {
      this.$refs.phone.focus();
    }
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    chect(){
      this.acceptance = !this.acceptance

    },
    submitThis() {
      if (
        this.$refs.phone.validity.valueMissing ||
        !this.$refs.phone.validity.valid
      ) {
        this.$refs.phone.setCustomValidity("Введите номер телефона");
      }
      if (
        !this.$refs.phone.validity.valueMissing &&
        !this.$refs.phone.validity.patternMismatch
      ) {
        this.$refs.phone.setCustomValidity("");
      }
      if (this.agreed && this.$el.reportValidity()) {
        // if (typeof ckForms !== "undefined") {
        //   ckForms.send(this.$el);
        // }

        // ym(57632524, 'reachGoal', 'send_form');

        // Отправка данных в метрику
        this.sendMetric('send_form')

        this.$emit("callBack", {
          phone: this.phone,
          data: this.data,
        });
      }
    },
    sendMetric,

  checkedString(str){
      return str || '_'
    },
    treeNumberString(...num){
      return this.checkedString(num[0]) + this.checkedString(num[1]) + this.checkedString(num[2])
    },
    twoNumberString(...num){
       return this.checkedString(num[0]) + this.checkedString(num[1])
    },
  },
  watch:{
    phone(newVal){
      let arra_phone = newVal.replace('+7','').split('')
      let indx_num = arra_phone.filter((el) => el != '_' && el != ' ')
      let final_str = `+7 ${this.treeNumberString(...indx_num.slice(0,4))} ${this.treeNumberString(...indx_num.slice(3,8))} ${this.twoNumberString(indx_num[6],indx_num[7])} ${this.twoNumberString(indx_num[8],indx_num[9])}`
      this.phone = final_str
    },
  },

  
};
</script>

<style scoped lang="scss">
@import "../../styles/constants.scss";

.mobile {
  .callback-form {
    &__container {
      align-items: center;
    }
  }

  &.contacts {
    .agreement {
      left: 0;
    }
  }
}

.waranty__form{
  .callback-form {
    &__input {
      background: transparent;
      color: #fff;
      &::placeholder {
      font-weight: 400;
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      padding: 0;
      overflow: visible;
    }
    }
  }
}

.horizontal {
  .callback-form {
    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
    }
    &__input {
      width: calc(50% - 12px);
      margin-bottom: 0;
      padding: 0 10px;
    }
    &__button {
      width: calc(50% - 12px);
    }
  }
}

.callback-form {
  max-width: 354px;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  &__input {
    background: transparent;
    // width: 100%;
    width: 360px;
    height: 56px;
    padding: 0 28px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Hyundai Sans Text';
    color: rgba(0, 0, 0, 1);
    background: #fff;
    border: 1px solid rgba(219, 219, 219, 1);
    outline: none;
    .block-contacts & {
      background: #f7f7f7;
    }

    &::placeholder {
      font-weight: 700;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.7);
      padding: 0;
      overflow: visible;
    }
  }

  &__button {
    width: 360px;
    font-weight: normal;
    font-size: 17px;
    font-family: 'Hyundai Sans Text';
    &_disabled {
      background: #000000;
      cursor: default;
    }
  }
}

.credit {
  .callback-form {
    &__input {
      border: 1px solid transparent;
      @media (max-width: 768px) {
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.agreement {
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  margin-top: 10px;
  padding-left: 23px;

  &__text {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: #000;

    .block-form & {
      color: #fff;
    }

    a {
      color: inherit;
    }
  }

  &__link {
    // color: $primary;
    cursor: pointer;
    text-decoration: none;

    .block-form & {
      border-color: #fff;
    }
  }
}
@media screen and (max-width: 591px){
  .callback-form__input{
    width: 100%;
  }
  .callback-form__button{
    width: 100%;
  }
}
@media screen and (max-width: 400px){
	.agreement__text{
		font-size: 11px;
	}
}
</style>
