<template>
  <footer class="footer" v-if="status === false">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__content" v-if="hide">
          <button @click="footer_text = !footer_text" class="footer__btn">*Подробности акции</button>
          <p :class=" footer_text? 'footer__content-text--active' :''" class="footer__content-text">
            <br /><br />9,00% - процентная ставка применяется при подключении к договору 
            потребительского кредита (далее - Договор) добровольной платной услуги Банка 
            «Назначь свою ставку 7», снижающей действующую процентную ставку по кредиту 
            на 7 процентных пункта. Услуга подключается не позднее даты заключения 
            Договора по программам кредитования со сроками 24, 36, 48, 60, 72, 84 месяцев 
            (размер комиссии согласно тарифам Банка), условиями которых предусмотрена 
            возможность ее подключения (при отказе от услуги действующая процентная ставка 
            увеличивается на 7 процентных пункта). Договор оформляется по акционной 
            программе кредитования «РОЛЬФ ФИНАНС» для владельцев «Карта «Халва» (далее - 
            акционная программа) по продукту «АвтоСтиль-Особый» на покупку новых 
            автомобилей и автомобилей с пробегом не старше 5 лет по договору, заключенному 
            с одним из юридических лиц, входящих в ГК «РОЛЬФ». Параметры акционной 
            программы: валюта кредита – рубли РФ; срок кредита – 24, 36, 48, 60, 72, 84 
            мес.; сумма кредита- от 90 000 до 8 000 000 руб.; процентная ставка – 16% 
            годовых, первоначальный взнос – от 0% от цены приобретаемого автомобиля (от 
            20 % - при сроке кредита 84 мес.). Акционной программой предусмотрено 
            оформление договора страхования автомобиля от рисков хищения (угона), утраты 
            (гибели). П роцентная ставка по кредиту, установленная акционной программой, 
            действует при соблюдении Заемщиком условий акции: совершение в каждом полном 
            отчетном периоде по «Карта Халва» в течение действия Договора от 5 расходных 
            операций на общую сумму от 10 000 рублей. В случае несоблюдения условий - 
            действующая процентная ставка увеличивается на 4 процентных пункта. Общий срок 
            проведения акции – с 10.10.2023 г. по 31.12.2023 г. Информация об организаторе 
            акции, о правилах ее проведения, вознаграждении по результатам акции, сроках, 
            месте и порядке их получения, иные условия акции указаны в Паспорте акции 
            «Автокредит с Халвой», размещенном на сайте Банка https://sovcombank.ru/. Для 
            оформления кредита необходим паспорт гражданина РФ. Залоговое обеспечение – 
            залог приобретаемого автомобиля. Условия по «Карта «Халва»: лимит до 500 000 
            руб. в течение 5 лет. Срок рассрочки от 1 до 18 мес. на покупки в сети 
            партнёров. Обслуживание – 0 руб. Ставка в течение льготного периода (36 мес.) 
            – 0% годовых. При его нарушении, по окончании – 0,0001% годовых на 
            задолженность с даты возникновения. Оформление по паспорту РФ: с лимитом 0,1 
            руб. - от 18 до 90 лет, с лимитом более 0,1 руб.- от 20 до 75 лет. ПСК по 
            программе РОЛЬФ ФИНАНС 9% - 11,719% - 15,985% годовых. Предложение действует 
            на 01 февраля 2023 года. ПАО «Совкомбанк», ИНН 4401116480, ОГРН 1144400000425, 
            КПП 440101001, 156000, г. Кострома, пр. Текстильщиков, д. 46 Генеральная 
            лицензия Банка России № 963 от 05 декабря 2014 г. Не является публичной 
            офертой.
            <br><br>1.1 Условия тарифа «Промо Рольф Лайт». Диапазоны значений полной 
            стоимости автокредита (ПСК) в процентах годовых: 13,324% - 13,590%. На 
            основании Сборника тарифов и процентных ставок по программам автокредитования 
            для физических лиц ПАО Банк «Открытие», действующего на 23.10.2023. Мин. 
            процентная ставка (ПС) 9% при оформлении кредита в сети дилерских центров РОЛЬФ 
            на новое транспортное средство (ТС) и ТС с пробегом, дисконта 6% при 
            подключении услуги «Ставка Автомобилиста» (СА) на весь срок кредита. Кредит 
            выдается при оформлении КАСКО и залога на приобретаемое ТС: первоначальный 
            взнос от 0 %, срок 36 – 84 мес., сумма кредита 300 тыс. – 12 млн руб., (ПСК - 
            14,976% - 15,005%) базовая ПС 15%.
            <br>Банк «Открытие» вправе вносить изменения в условия кредитования, тарифы, 
            состав услуг и параметры банковских продуктов по собственному усмотрению. 
            Решение о выдаче принимается индивидуально. Банк вправе отказать без 
            объяснения причин. Не оферта. Подробная информация в подразделениях ПАО Банк 
            «ФК Открытие», на open.ru и по телефону 8 (800) 444-44-00. ПАО Банк «ФК 
            Открытие», Москва, ул. Летниковская, д. 2, стр. 4 Ген. лиц. Банка России № 
            2209 от 24.11.2014. Реклама. 16+
            <br><br>1.2 АО «Альфа-Банк» (ИНН 7728168971 / КПП 770801001 / ОГРН 
            1027700067328, адрес: 107 078, г. Москва, ул. Каланчевская, д. 27, ген. 
            лицензия ЦБ РФ №1326 от 16.01.2015 г.) Кредит предоставляется на срок до 
            96 месяцев. Процентная ставка 9% возможна при оформлении полиса добровольного 
            страхования жизни и здоровья заемщика и подключения программы «Альфа Авто 
            Защита» на 1 год, первоначальный взнос от 30% по тарифу «Твоя выгода» на новые 
            автомобили и автомобили с пробегом с возрастом не старше 15 лет – для 
            иностранного производства и 10 лет – для китайского и отечественного 
            производства на дату оформления кредита. Сумма кредита до 10 000 000 рублей. 
            ПСК 14,09% (на срок 96 мес, с дополнительными продуктами «Твоя ставка» и 
            «Страхование от потери работы + ДМС» с датой первого платежа через 45 дней).
            <br><br>1.3 Филиал РОСБАНК Авто Публичного акционерного общества РОСБАНК, 
            Генеральная лицензия Банка России №2272 от 28.01.2015 года. Ставка по кредиту 
            9,0% на срок кредита 84 и 96 месяцев при оформлении автокредита по выделенным 
            тарифам с КАСКО. Предложение распространяется на новые автомобили и автомобили 
            с пробегом с первоначальным взносом от 20% и более. Ставка 9% при применении 
            опции «назначь свою ставку» (НСС). Стоимость НСС – 1,54% в год. Обеспечение по 
            кредиту - залог приобретаемого автомобиля. Условия и тарифы могут быть 
            изменены банком в одностороннем порядке. Банк в праве отказать в выдаче 
            автокредита. При отсутствии НСС применяется базовая ставка 18.7% на весь срок 
            кредита. Требования к заемщику: 
            <br>∙ гражданство РФ 
            <br>∙ регистрация и постоянное проживание в регионе присутствия Росбанк Авто 
            <br>∙ возраст: от 23 до 70 лет на момент погашения кредита 
            <br>∙ официальное трудоустройство на территории РФ 
            <br>∙ непрерывный трудовой стаж на последнем месте работы — не менее 3 месяцев 
            (при оформлении кредита на новый автомобиль) 
            <br>∙ непрерывный трудовой стаж на последнем месте работы — не менее 4 месяцев 
            <br>∙ предоставление не менее 2 контактных телефонов 
            <br>Требования к автомобилю: 
            <br>Максимальная сумма кредита: 12 000 000 руб. 
            <br>Минимальная сумма кредита: 100 000 руб.
            <br>ПСК 11,461%: Стоимость авто 2 000 000, ПВ 30%, срок 84 Тариф БУ Акция 0 
            Gold ПВ от 20% и более БИ+НСС Рольф
            <br><br>
            Страховые компании-партнеры программы РОЛЬФ Финанс:
            <br>1.  АO «АльфаСтрахование». ИНН 7713056834 / КПП 772501001 / ОГРН 
            1027739431730, адрес: 115162, Москва, ул. Шаболовка, 31, стр Б, регистрационный 
            номер 2239 в ЕГР ССД, СИ № 2239 от 13.11.2017 г.
            <br>2.  АО «Тинькофф Страхование». ИНН 7704082517 КПП 771301001 /ОГРН 
            1027739031540, адрес: 127287, г. Москва, 2-ая Хуторская улица, дом 38А, стр. 
            26), лицензия СИ № 0191 от 19.05.2015г.
            <br>3.  СПАО «Ингосстрах». ИНН 7705042179/КПП 770501001/ОГРН 
            1027739362474115035, адрес: г. Москва, Пятницкая ул., д. 12, стр. 2, лицензия 
            ЦБ РФ на осуществление страхования СИ №0928 от 23.09.2015г. без ограничения 
            срока действия
            <br>4.  САО «ВСК» (ИНН 7710026574/ КПП 773101001/ ОГРН 1027700186062, адрес: 
            121552, г. Москва, ул. Островная, д. 4), лицензии Банка России от 11.09.2015: 
            СЛ № 0621, СИ №0621.
            <br>5.  ПАО «Группа Ренессанс Страхование» https://www.renins.ru/about/licenses
          </p>
        </div>
        <div class="footer__inf">
          <a href="https://www.rolf.ru/policy/" target="_blank" rel="noopener">Политика АО «РОЛЬФ» в области обработки и обеспечения безопасности персональных данных</a>
          <p class="footer__inf-item">
            Данный сайт носит исключительно информационно-справочный характер, не является публичной офертой.
          </p>
          <p class="footer__inf-item">
            Подробности акции по телефону +7 (495) 788-78-12
          </p>

          <p class="footer__inf-item">
            АО «РОЛЬФ»
            <br>Юр.адрес: 141410, Россия, Московская область, г. Химки, Ленинградское шоссе, владение №21
            <br>ИНН 5047254063, ОГРН: 1215000076279 от 27.07.2021г.
          </p>
        </div>
      </div>
    </div>
  </footer>

  <footer class="footer__modal" v-else>
    <div :class="modal">
      <div class="blacking_modal" @click="status = !status"></div>
      <div class="get__container">
        <div class="get__body">
          <img
                  src="../images/utils/close.svg"
                  alt="Закрыть"
                  class="get__close"
                  width="20px"
                  height="20px"
                  @click="status = !status"
          />
          <h3 class="get__title font_type_block-title">
            Политика АО «РОЛЬФ» в области обработки и обеспечения безопасности персональных данных
          </h3>
          <p><br>
            1. ОБЩИЕ ПОЛОЖЕНИЯ
            <br><br>1.1. Настоящая Политика содержит описание принципов и подходов АО «РОЛЬФ» (далее – Компания) в отношении обработки и защиты персональных данных, обязанности и ответственность Компании при осуществлении такой обработки.

            <br><br>1.2. Компания обеспечивает соблюдение прав и свобод граждан при обработке персональных данных, в том числе обеспечивает защиту прав на неприкосновенность частной жизни, личной и семейной тайн.

            <br><br>1.3. При обработке персональных данных в Компании строго соблюдаются следующие принципы, предусмотренные Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных»:

            <br>законность целей и способов обработки персональных данных и добросовестности;
            соответствие целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Компании;
            соответствие объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;
            обеспечение достоверности персональных данных, их актуальности и достаточности для целей обработки, недопустимости обработки избыточных по отношению к целям сбора персональных данных;
            реализация организационных и технических мер по обеспечению безопасности персональных данных;
            повышение уровня знаний работников Компании в сфере обеспечения безопасности персональных данных при их обработке;
            стремления к постоянному совершенствованию системы защиты персональных данных.
            <br><br>2. СУБЪЕКТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ И ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            <br><br>2.1. В Компании осуществляется обработка персональных данных следующих категорий субъектов:

            <br>кандидаты на вакантные должности;
            работники Компании, в том числе бывшие;
            родственники работников Компании;
            работники группы компаний «РОЛЬФ»;
            клиенты, представители клиентов;
            контрагенты по гражданско-правовым договорам (представители юридических лиц, индивидуальные предприниматели, физические лица);
            пользователи сайта.
            <br><br>2.2. Основными целями обработки персональных данных в Компании являются:

            <br>рассмотрение резюме и подбор кандидатов на вакантную должность для дальнейшего трудоустройства в Компанию;
            реализация прав и исполнение обязательств, возникших в связи с трудовыми отношениями с работниками;
            ведение корпоративных информационных справочников;
            предоставление социальных льгот работникам Компании и близким родственникам работников;
            оформление работникам Компании полисов добровольного медицинского страхования;
            оформление работникам Компании банковских карт в рамках «зарплатного проекта»;
            заключение гражданско-правовых договоров с юридическими лицами, индивидуальными предпринимателями, физическими лицами, а также выполнение обязательств, связанных с договорными правоотношениями, которые регулируются законом или договором;
            заключение, сопровождение, изменение договоров купли-продажи, ремонта, обслуживания автомобилей;
            предоставление услуг по прокату, ремонту и обслуживанию автомобилей;
            оказание содействия клиентам в заключении кредитных договоров и договоров страхования;
            обработка обращений клиентов;
            проведение маркетинговых мероприятий и поддержка клиентских программ;
            обеспечение взаимодействия со СМИ;
            анализ статистики посещаемости, пользовательской активности и оптимизации сайта Компании;
            осуществление пропускного режима на территории Компании.
            <br><br>3. ПРАВА И ОБЯЗАННОСТИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
            <br><br>3.1. Субъект персональных данных имеет право:

            <br>получить сведения, касающиеся обработки своих персональных данных;
            потребовать исправления неверных, неточных, устаревших персональных данных или уничтожения персональных данных в случае их неправомерной обработки;
            отозвать согласие на обработку персональных данных;
            обжаловать в суде любые неправомерные действия или бездействие Компании при обработке и защите его персональных данных.
            <br><br>3.2. Порядок направления субъектом персональных данных запроса на предоставление сведений об обработке персональных данных определен требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных». В частности, в соответствии с указанными требованиями запрос, направляемый в Компанию, должен содержать:

            <br>серию, номер документа, удостоверяющего личность субъекта персональных данных, сведения о дате выдачи указанного документа и выдавшем его органе;
            сведения, подтверждающие участие субъекта персональных данных в отношениях с Компанией (номер договора, дата заключения договора, условное словесное обозначение и/или иные сведения), либо сведения, иным образом подтверждающие факт обработки
          </p>


        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    data(){
      return{
        footer_text:false,
        status: false,
        hide: false

      }
    },
    computed: {
      modal: function() {
        if(this.status === true) {
          return 'block'
        } else {
          return 'none'
        }
      }
    },
  }
</script>

<style lang="sass" scoped>


  .footer__modal
    overflow: hidden
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1000

    .get__container
      position: absolute
      top: 100vh
      left: 50vw
      transform: translate(-50%, -50%)
      width: 100%
      max-width: 95vw
      display: flex
      justify-content: center
      z-index: 500
      pointer-events: none

    .get__body
      position: relative
      max-width: 800px
      padding: 35px 35px 50px
      background: #fff
      pointer-events: all

    .get__close
      position: absolute
      right: 10px
      top: 10px
      cursor: pointer

    .blacking_modal
      position: absolute
      top: -100vh
      left: -100vw
      bottom: -100vw
      right: 0
      background: #000
      opacity: 0.5
      cursor: default
      z-index: 300

    .block
      position: absolute
      top: 0px
      bottom: 0
      left: 0
      right: 0
      width: 100vw
      z-index: 1000
      display: block
      overflow-y: scroll


    @media screen and (max-width: 580px)
      .get__container
        top: 132vh





  .footer
    background-color: rgba(13, 74, 129, 1)
    min-height: 150px
    margin-top: 10px
    font-size: 14px
    color: #fff
    line-height: 17px
    .link-modal
      cursor: pointer
    &none
      display: none
    &__content
      &-text
        max-height: 0px
        overflow: hidden
        transition: all 0.8s
        &--active
          max-height: 900px
    &__inner
      padding: 41px 0px 20px
    &__inf
      margin-top: 25px
      a 
        color: inherit
    &__btn
      font-size: 14px
      background-color: transparent
      color: #fff
      border: none
      cursor: pointer
      text-decoration: underline
      line-height: 17px


  @media screen and (max-width: 587px)
    .footer
      min-height: 200px
    .footer__inner
      padding: 41px 15px
</style>
